body {
  margin: 0;
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  /* font-weight: normal; */
}

audio, canvas, embed, iframe, img, object, svg, video {
  display: block;
  vertical-align: middle;
}

img, video {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

legend {
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}



/* Accessibility */
.visuallyHidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}



/* Focus-visible polyfill */
/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
:focus:not(input, textarea) {
  outline: black solid 2px !important;
}
:focus:not([data-focus-visible-added]) {
  outline: 2px transparent !important;
}